import React from 'react';
import ReactDOM from "react-dom";
import { Pages } from "./Pages";
import { diary } from "../../index";
import { GroupsPayments } from "./GroupsPayments";
import { legacy } from "../../legacy";
import {PersonDependentDropList} from "../components/PersonDependentDropList";
import {PersonDependentElements} from "../enums/PersonDependentElements";

const axios = require("axios").default;


export class StudentsDetail extends Pages {

    initPageHandlers() {
        super.initPageHandlers();
        this.showContactsPassport = this.showContactsPassport.bind(this);

        this.data = window.diaryJsData;

        //TODO обработка части выводимых блоков по старой схеме - почистить
        globObj.sID = this.data.elementId;

        $(document).on("click", "#passport-show-button", this.showContactsPassport);

        $(document).on("change keyup input click", ".edit-student-payment", (event) => {
            let $clicked = $(event.target);
            event.preventDefault();
            diary.getDataInModal("student.payments", $clicked.attr("data-payments-detail"));
            diary.onLoadInModal = GroupsPayments.initInModalHandler;
        });

        $(document).on("click.editStudentContact", "[data-contact-id]", (event) => {
            event.preventDefault();
            const cID = event.currentTarget.getAttribute("data-contact-id");
            legacy.studentEditContactsForm(cID);
        });

        ReactDOM.render(
            <PersonDependentDropList
                placeholder={"быстрый поиск слушателей"}
                entity={PersonDependentElements.STUDENT} />,
            document.getElementById('list-search-form-component'),
        );

    }


    showContactsPassport(event) {
        let $clicked = $(event.currentTarget),
            $span = $clicked.prev(),
            contactId = $clicked.attr("data-passport-contact-id"),
            $spinner = $('<span class="spinner-border spinner-border-sm ms-2" ' +
                'role="status" aria-hidden="true"></span>');

        event.preventDefault();
        $clicked.append($spinner);

        const refreshPassportData = (contact) => {
            $span.html(contact);
            $clicked.remove();
        };

        //TODO - на новый API

        axios.post('/app/models/ajax/getContactsData.php',
            {
                id: contactId,
                field: "document"
            }
        )
            .then((response) => {
                if (response.data.result === "success") {
                    refreshPassportData(response.data.contact);
                } else {
                    throw new Error("Не удалось получить данные паспорта!");
                }
            })
            .catch((error) => {
                console.log(error);
                throw new Error("Не удалось выполнить запрос!");
            });

    }

}
